import { add, startOfToday } from 'date-fns';
import { Event, InterviewResource } from '../../models/interview';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const testEvents: Event<InterviewResource>[] = [
  {
    title: 'Event 1',
    start: add(startOfToday(), { hours: 8 }),
    end: add(startOfToday(), { hours: 9 }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 2',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 3',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 4',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 5',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 6',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 7',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 8',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 9',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
  {
    title: 'Event 10',
    start: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    end: add(startOfToday(), { days: getRandomInt(10), hours: getRandomInt(23) }),
    resource: {
      interviewLocation: 'Location 1',
    },
  },
];
