import { differenceInDays, format, parseISO } from 'date-fns';

export function localDateFromISO(timestamp: string): string {
  return format(parseISO(timestamp), 'P');
}

export function daysUntil(timestamp: string): number {
  return Math.max(0, differenceInDays(parseISO(timestamp), new Date()));
}

export const timeOptions = [
  { value: '12:00am', label: '12:00am' },
  { value: '12:30am', label: '12:30am' },
  { value: '1:00am', label: '1:00am' },
  { value: '1:30am', label: '1:30am' },
  { value: '2:00am', label: '2:00am' },
  { value: '2:30am', label: '2:30am' },
  { value: '3:00am', label: '3:00am' },
  { value: '3:30am', label: '3:30am' },
  { value: '4:00am', label: '4:00am' },
  { value: '4:30am', label: '4:30am' },
  { value: '5:00am', label: '5:00am' },
  { value: '5:30am', label: '5:30am' },
  { value: '6:00am', label: '6:00am' },
  { value: '6:30am', label: '6:30am' },
  { value: '7:00am', label: '7:00am' },
  { value: '7:30am', label: '7:30am' },
  { value: '8:00am', label: '8:00am' },
  { value: '8:30am', label: '8:30am' },
  { value: '9:00am', label: '9:00am' },
  { value: '9:30am', label: '9:30am' },
  { value: '10:00am', label: '10:00am' },
  { value: '10:30am', label: '10:30am' },
  { value: '11:00am', label: '11:00am' },
  { value: '11:30am', label: '11:30am' },
  { value: '12:00pm', label: '12:00pm' },
  { value: '12:30pm', label: '12:30pm' },
  { value: '1:00pm', label: '1:00pm' },
  { value: '1:30pm', label: '1:30pm' },
  { value: '2:00pm', label: '2:00pm' },
  { value: '2:30pm', label: '2:30pm' },
  { value: '3:00pm', label: '3:00pm' },
  { value: '3:30pm', label: '3:30pm' },
  { value: '4:00pm', label: '4:00pm' },
  { value: '4:30pm', label: '4:30pm' },
  { value: '5:00pm', label: '5:00pm' },
  { value: '5:30pm', label: '5:30pm' },
  { value: '6:00pm', label: '6:00pm' },
  { value: '6:30pm', label: '6:30pm' },
  { value: '7:00pm', label: '7:00pm' },
  { value: '7:30pm', label: '7:30pm' },
  { value: '8:00pm', label: '8:00pm' },
  { value: '8:30pm', label: '8:30pm' },
  { value: '9:00pm', label: '9:00pm' },
  { value: '9:30pm', label: '9:30pm' },
  { value: '10:00pm', label: '10:00pm' },
  { value: '10:30pm', label: '10:30pm' },
  { value: '11:00pm', label: '11:00pm' },
  { value: '11:30pm', label: '11:30pm' },
];
