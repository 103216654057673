import React, { useState } from 'react';
import { Modal } from './Modal';
import { CalendarIcon, ClockIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { format, parse, set, startOfToday } from 'date-fns';
import { InterviewEvent } from '../models/interview';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { timeOptions } from '../utils/date';
import { useImmer } from 'use-immer';
import clsx from 'clsx';
import { toast } from 'react-toastify';

const CalendarEventModal = ({
  currentEvent,
  eventModalState,
}: {
  currentEvent?: InterviewEvent;
  eventModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) => {
  // const formData = new FormData(e.currentTarget);
  const [currentSection, setCurrentSection] = useState<'Upcoming' | 'Cancel' | 'Reschedule'>('Upcoming');
  const [isEventModalOpen, setIsEventModalOpen] = eventModalState;
  const closeModal = () => setIsEventModalOpen(false);
  const sections = {
    Upcoming: <Upcoming key="Upcoming" setCurrentSection={setCurrentSection} currentEvent={currentEvent} />,
    Reschedule: <Reschedule key="Reschedule" currentEvent={currentEvent} closeModal={closeModal} />,
    Cancel: (
      <Cancel key="Cancel" setCurrentSection={setCurrentSection} currentEvent={currentEvent} closeModal={closeModal} />
    ),
  };
  return (
    <Modal isOpenState={eventModalState}>
      <h2 className="pb-4 text-xl">{currentSection} Interview</h2>
      {sections[currentSection]}
    </Modal>
  );
};

const Upcoming = ({
  currentEvent,
  setCurrentSection,
}: {
  currentEvent?: InterviewEvent;
  setCurrentSection: React.Dispatch<React.SetStateAction<'Upcoming' | 'Cancel' | 'Reschedule'>>;
}) => {
  const { title, start, resource } = currentEvent || {};
  return (
    <div className="flex flex-col gap-3 rounded-lg bg-primary/10 p-8">
      <div className="flex items-center gap-3">
        <div className="rounded-xl bg-red-100 p-2">
          <CalendarIcon className="h-6 w-6 text-red-400" />
        </div>
        <span className="flex-grow text-center">{title}</span>
      </div>

      <div className="flex justify-center gap-3">
        <ClockIcon className="h-6 w-6 text-primary" />
        <span>{start && format(start, 'EEEE, h aa LLLL do yyy')}</span>
      </div>
      <div className="flex justify-center gap-3">
        <MapPinIcon className="h-6 w-6 text-primary" />
        <span>{resource?.interviewLocation}</span>
      </div>
      <button className="btn btn-primary" onClick={() => setCurrentSection('Reschedule')}>
        Reschedule
      </button>
      <button className="btn text-red-500" onClick={() => setCurrentSection('Cancel')}>
        Cancel Interview
      </button>
    </div>
  );
};

const Cancel = ({
  currentEvent,
  closeModal,
  setCurrentSection,
}: {
  currentEvent?: InterviewEvent;
  closeModal: () => void;

  setCurrentSection: React.Dispatch<React.SetStateAction<'Upcoming' | 'Cancel' | 'Reschedule'>>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setIsModalOpen(true);
  }

  function cancelInterview(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    try {
      // TODO: Send Cancellation to backend
      // throw new Error('Function not implemented.');
      setIsSuccess(true);
    } catch (error) {
      toast.error('Unable to cancel interview. Please try again');
    }
    setIsModalOpen(false);
  }

  const KeepInterviewModal = () => (
    <Modal isOpenState={[isModalOpen, setIsModalOpen]}>
      <h2 className="pb-4 text-xl">Keep Interview</h2>
      <p className="py-3">Would you like to reschedule this interview instead?</p>
      <div className="flex justify-end gap-3 border-t border-subtitle/20 pt-3">
        <button className="btn bg-primary text-white" type="button" onClick={() => setCurrentSection('Reschedule')}>
          Yes, reschedule
        </button>
        <button className="btn btn-primary bg-red-500" type="button" onClick={cancelInterview}>
          No, cancel interview
        </button>
      </div>
    </Modal>
  );
  return (
    <>
      <KeepInterviewModal />
      {!isSuccess ? (
        <div className="space-y-3">
          <h3 className="py-2 text-center">Are you sure you want to cancel this interview?</h3>
          <InterviewInfo currentEvent={currentEvent} />
          <form onSubmit={handleSubmit} className="space-y-3" id="cancelInterview" name="cancelInterview">
            <h2 className="text-lg font-bold">Tell us more</h2>
            <div>
              <p className="text-lg">Has the role been filled?</p>
              <label>
                <input required className="mr-2" type="radio" name="isFilled" />
                Yes
              </label>
              <label>
                <input required className="mr-3" type="radio" name="isFilled" />
                No
              </label>
            </div>

            <div>
              <p className="text-lg">If no, is your reason for cancelling any of the following?</p>
              <label>
                <input className="mr-3" type="checkbox" name="cancellationReasons" />
                Position is on hold/eliminated
              </label>
              <label>
                <input className="mr-3" type="checkbox" name="cancellationReasons" />
                Schedule conflicts
              </label>
              <label>
                <input className="mr-3" type="checkbox" name="cancellationReasons" />
                Other
              </label>
            </div>

            <div className="flex justify-end gap-3 border-t border-subtitle/20 pt-3">
              <button className="btn" type="button" onClick={closeModal}>
                Keep Interview
              </button>
              <button className="btn btn-primary" /* type="button" onClick={() => setIsModalOpen(true)} */>
                Confirm
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col items-center bg-green-500/10 p-4 text-lg">
          <h3 className="text-2xl">Success!</h3>
          <CheckCircleIcon className="h-16 w-16 text-green-500" />
          <p className="text-subtitle">Interview has been successfully cancelled.</p>
          <p className="text-subtitle">We will inform all participants about the cancelation.</p>
        </div>
      )}
    </>
  );
};

const Reschedule = ({ currentEvent, closeModal }: { currentEvent?: InterviewEvent; closeModal: () => void }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [newInterview, setNewInterview] = useImmer<InterviewEvent>(currentEvent!);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setIsSuccess(true);
    // throw new Error('Function not implemented.');
  }

  function handleTimeChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    if (name != 'start' && name != 'end') return;

    const parsedDate = parse(value, 'h:mmaa', new Date());
    const hours = parsedDate.getHours();
    const minutes = parsedDate.getMinutes();

    setNewInterview((draft) => {
      const currentDate = draft[name];
      if (!currentDate) return draft;
      draft[name] = set(currentDate, { hours, minutes, seconds: 0 });

      return draft;
    });
  }

  function handleDateChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // 2025-02-28 is the date format
    const [year, month, date] = event.target.value.split('-');
    setNewInterview((draft) => {
      if (!draft.start) return draft;
      draft.start = set(draft.start, { date: parseInt(date), month: parseInt(month), year: parseInt(year) });
      return draft;
    });
  }

  const currentDateValue = format(newInterview.start || startOfToday(), 'yyyy-MM-dd');
  const currentStartValue = format(newInterview.start || startOfToday(), 'h:mmaa').toLowerCase();
  const currentEndValue = format(newInterview.end || startOfToday(), 'h:mmaa').toLowerCase();

  return (
    <>
      {!isSuccess ? (
        <form onSubmit={handleSubmit} className=" flex flex-col gap-3">
          <span className="-mb-1">Select a new day</span>
          <label>
            <input required type="date" name="" id="" onChange={handleDateChange} value={currentDateValue} />
          </label>

          <span className="-mb-1">Select a new time</span>
          <div className="flex items-center gap-5">
            <select required className="pr-7" name="start" onChange={handleTimeChange} value={currentStartValue}>
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value} className="">
                  {option.label}
                </option>
              ))}
            </select>
            <span>-</span>
            <select required className="pr-7" name="end" onChange={handleTimeChange} value={currentEndValue}>
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end gap-3 border-t border-subtitle/20 pt-3">
            <button className="btn" type="button" onClick={closeModal}>
              Cancel
            </button>
            <button className="btn btn-primary" /* type="button" onClick={() => setIsModalOpen(true)} */>
              Confirm
            </button>
          </div>
        </form>
      ) : (
        <div className="flex flex-col items-center gap-2 bg-green-500/10 p-4 text-lg">
          <h3 className="text-2xl">Success!</h3>
          <CheckCircleIcon className="h-16 w-16 text-green-500" />
          <p className="text-subtitle">Interview has been successfully rescheduled.</p>
          <InterviewInfo currentEvent={newInterview} className="w-full max-w-xs bg-white" />
        </div>
      )}
    </>
  );
};

const InterviewInfo = ({ currentEvent, className }: { currentEvent?: InterviewEvent; className?: string }) => {
  const { title, start, end } = currentEvent || {};
  if (start == undefined || end == undefined) return null;
  return (
    <div className={clsx('flex flex-col items-center rounded-lg bg-primary/10 p-4 text-center text-sm', className)}>
      <CalendarIcon className="h-12 w-12 text-primary" />
      <p className="text-lg font-bold">{title}</p>
      <p>{format(start, 'eeee MMM d')}</p>
      <p>
        {format(start, 'hh:mmaaa')} - {format(end, 'hh:mmaaa')}
      </p>
    </div>
  );
};

export default CalendarEventModal;
