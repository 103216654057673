import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, addDays, eachDayOfInterval, isSameDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import clsx from 'clsx';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import './calendar.scss';
import { Modal } from './Modal';
import { InterviewEvent } from '../models/interview';
import { testEvents } from '../stories/fixtures/interviews';
import CalendarEventModal from './CalendarEventModal';
import { timeOptions } from '../utils/date';

function Interviews() {
  const [currentDate, setDate] = useState(new Date());
  const [events, setEvents] = useState<InterviewEvent[]>(testEvents);
  const [view, setView] = useState<'day' | 'week' | 'month'>('day');

  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<InterviewEvent>();

  const locales = {
    'en-US': enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const nearestWeekStartDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endDate = addDays(nearestWeekStartDate, 6);
  const week = eachDayOfInterval({ start: nearestWeekStartDate, end: endDate }).map((d) => {
    return {
      date: format(d, 'd'),
      day: format(d, 'eee').toUpperCase(),
      raw: d,
    };
  });

  const getToolbarLabelText: string = (() => {
    // Day: Wednesday Oct 23
    // Week: October 20 - 26
    // Month: October 2024
    switch (view) {
      case 'day':
        return `${format(currentDate, 'eeee MMM d')}`;
      case 'week':
        return `${format(nearestWeekStartDate, 'MMMM d')} - ${format(addDays(nearestWeekStartDate, 6), 'd')}`;
      case 'month':
        return `${format(currentDate, 'MMMM yyyy')}`;
    }
  })();

  const Toolbar = () => (
    <div className="flex items-center gap-5 px-5 py-5" onClick={(e) => e.stopPropagation()}>
      <h2 className="mr-auto text-3xl font-bold text-black">{getToolbarLabelText}</h2>
      <button
        className={'rounded-lg border border-subtitle/20 px-4 py-1.5 text-lg text-black '}
        onClick={() => setDate(new Date())}
      >
        Today
      </button>

      <div className="flex gap-1">
        <button
          className={clsx(
            'rounded-lg px-4 py-1.5 font-bold',
            view === 'day' ? 'border-none bg-primary text-white' : 'bg-subtitle/20 font-normal text-gray-500'
          )}
          onClick={() => setView('day')}
        >
          Daily
        </button>
        <button
          className={clsx(
            'rounded-lg px-4 py-1.5 font-bold',
            view === 'week' ? 'border-none bg-primary text-white' : 'bg-subtitle/20 font-normal text-gray-500'
          )}
          onClick={() => setView('week')}
        >
          Weekly
        </button>
        <button
          className={clsx(
            'rounded-lg px-4 py-1.5 font-bold',
            view === 'month' ? 'border-none bg-primary text-white' : 'bg-subtitle/20 font-normal text-gray-500'
          )}
          onClick={() => setView('month')}
        >
          Monthly
        </button>
      </div>

      <div className="flex items-center rounded-lg border-2 border-subtitle/20 ">
        <ChevronLeftIcon className="h-9 px-2 py-2 text-subtitle" role="button" />
        <button className="px-8 py-1.5">January 2022</button>
        <ChevronRightIcon className="h-9 px-2 py-2 text-subtitle" role="button" />
      </div>
    </div>
  );

  const changeWeek = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setDate(addDays(nearestWeekStartDate, 7));
    } else {
      setDate(addDays(nearestWeekStartDate, -7));
    }
  };

  const DayCycle = () => (
    <div className="flex justify-between border-t border-subtitle/20 py-3" onClick={(e) => e.stopPropagation()}>
      <button type="button" className="group">
        <ChevronLeftIcon
          aria-hidden="false"
          className="-order-10 mr-auto h-7 flex-shrink-0 fill-subtitle group-enabled:hover:fill-primary"
          onClick={() => changeWeek('prev')}
        />
      </button>
      {week.map((date) => (
        <div key={date.date} className="">
          <input
            className="peer hidden"
            type="radio"
            name="date"
            id={date.date}
            onChange={() => setDate(date.raw)}
            checked={isSameDay(date.raw, currentDate)}
          />
          <label
            htmlFor={date.date}
            className={clsx(
              'relative flex cursor-pointer flex-col items-center rounded-lg border bg-subtitle/20 px-6 py-2 peer-checked:border-primary peer-checked:bg-white peer-checked:text-primary',
              isSameDay(date.raw, new Date()) && !isSameDay(date.raw, currentDate) && '!bg-primary/20 text-primary'
            )}
          >
            <span className="text-xl">{date.date}</span>
            <span className="text-xs text-subtitle">{date.day}</span>
          </label>
        </div>
      ))}
      <button type="button" className="group">
        <ChevronRightIcon
          aria-hidden="false"
          className="order-10 ml-auto h-7 flex-shrink-0 fill-subtitle group-enabled:hover:fill-primary"
          onClick={() => changeWeek('next')}
        />
      </button>
    </div>
  );

  const scrollToEarliestTime: Date = (() => {
    // Find the earliest start time of the events of the day or week
    // So user doesn't have to scroll down to see the first event
    const earliestTime = events.reduce(
      (earliest, event) => {
        if (event.start == undefined) return earliest;
        return event.start < earliest ? event.start : earliest;
      },
      events.length && events[0].start !== undefined ? events[0].start : new Date()
    );

    return earliestTime;
  })();

  const handleEventClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, event: InterviewEvent) => {
    e.stopPropagation();
    setIsEventModalOpen(true);
    setCurrentEvent(event);
  };

  const DayEvent = ({ event }: { event: InterviewEvent }) => {
    {
      if (event.start == undefined || event.end == undefined) return null;
      return (
        <div
          className="flex h-full w-full flex-col flex-wrap  gap-2 border-l-2 border-primary bg-lightblue px-4 py-2 text-black"
          onClick={(e) => handleEventClick(e, event)}
        >
          <h2 className="text-lg font-bold">{event.title}</h2>
          <p className="">
            {format(event.start, 'hh:mmaaa')} - {format(event.end, 'hh:mmaaa')}
          </p>
          <p>{event.resource?.interviewLocation}</p>
        </div>
      );
    }
  };

  const WeekEvent = ({ event }: { event: InterviewEvent }) => {
    {
      if (event.start == undefined || event.end == undefined) return null;
      return (
        <div
          className="flex h-full w-full flex-col justify-center border-l-2 border-primary bg-lightblue px-4 py-2 text-sm text-black"
          onClick={(e) => handleEventClick(e, event)}
        >
          <h2 className="text-base font-bold">{event.title}</h2>
          <p className="text-subtitle">
            {format(event.start, 'hh:mmaaa')} - {format(event.end, 'hh:mmaaa')}
          </p>
        </div>
      );
    }
  };
  const MonthEvent = ({ event }: { event: InterviewEvent }) => {
    {
      if (event.start == undefined || event.end == undefined) return null;
      return (
        <div
          className="flex h-full w-full flex-col justify-center border-l-2 border-primary bg-lightblue px-4 py-2 text-sm text-black"
          onClick={(e) => handleEventClick(e, event)}
        >
          <h2 className="text-base font-bold">{event.title}</h2>
          <p className="text-subtitle">
            {format(event.start, 'hh:mmaaa')} - {format(event.end, 'hh:mmaaa')}
          </p>
        </div>
      );
    }
  };

  const openAvailabilityModal = () => {
    console.log('open availability modal');
    setIsAvailabilityModalOpen(true);
  };

  const SetAvailabilityModal = () => {
    const submitAvailability = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      setIsAvailabilityModalOpen(false);
    };
    const DayAvailability = () => {
      const day = (dayOfWeek: string) => {
        const [isAvailable, setIsAvailable] = useState(false);
        return (
          <div className="flex items-center justify-center gap-5">
            <label className="flex gap-3">
              <input
                type="checkbox"
                name={`${dayOfWeek}-Checkbox`}
                className=""
                onChange={() => setIsAvailable((prev) => !prev)}
                value={isAvailable.toString()}
              />
              <span className="w-20">{dayOfWeek}</span>
            </label>

            {isAvailable ? (
              <>
                <select className="pr-7" name={`${dayOfWeek}-Start`}>
                  {timeOptions.map((option) => (
                    <option key={option.value} value={option.value} className="">
                      {option.label}
                    </option>
                  ))}
                </select>
                <span>-</span>
                <select className="pr-7" name={`${dayOfWeek}-End`}>
                  {timeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <span className="text-gray-500">Not available</span>
            )}
          </div>
        );
      };

      return (
        <div className="flex flex-col items-start gap-4">
          {day('Monday')}
          {day('Tuesday')}
          {day('Wednesday')}
          {day('Thursday')}
          {day('Friday')}
          {day('Saturday')}
          {day('Sunday')}
        </div>
      );
    };
    return (
      <Modal isOpenState={[isAvailabilityModalOpen, setIsAvailabilityModalOpen]}>
        <form onSubmit={submitAvailability}>
          <h2 className=" text-xl">Availability</h2>

          <div className="py-5 text-sm">
            <p>Tuesday, January 19, 2022 9:30am - 10:00 am</p>
            <label className="flex items-center gap-5">
              <span className="whitespace-nowrap">Time zone</span>
              <select name="" id="">
                <option value="CT">Central Time - US & Canada</option>
              </select>
            </label>
          </div>

          <div>
            <h3 className="pb-2 text-xl">Repeat weekly</h3>

            <DayAvailability />
          </div>

          <div className="flex justify-end gap-5 pt-5">
            <button type="button" className="btn" onClick={() => setIsAvailabilityModalOpen(false)}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      </Modal>
    );
  };

  return (
    <>
      <SetAvailabilityModal />
      {isEventModalOpen && (
        <CalendarEventModal eventModalState={[isEventModalOpen, setIsEventModalOpen]} currentEvent={currentEvent} />
      )}
      <main className="h-full" onClick={openAvailabilityModal}>
        <Calendar
          date={currentDate}
          view={view}
          events={events}
          localizer={localizer}
          views={['day', 'month', 'week']}
          startAccessor="start"
          endAccessor="end"
          className="h-full"
          scrollToTime={scrollToEarliestTime}
          components={{
            toolbar: () => (
              <>
                <Toolbar />
                {view === 'day' && <DayCycle />}
              </>
            ),
            timeGutterHeader: () => <span className="">All Day</span>,
            day: {
              event: DayEvent,
            },
            week: {
              event: WeekEvent,
            },
            month: {
              event: MonthEvent,
            },
          }}
        />
      </main>
    </>
  );
}

export default Interviews;
